import React from 'react'
import { cx, css } from 'linaria'

import _03 from './03.png'
import { ReactComponent as BatterySvg } from './svg/battery.svg'

const SpecificFeatureSaveBattery = () => {
  const orderIcon = _03
  const title = 'Saving battery life'
  const desc = 'Power up the Dark Mode in macOS Mojave and reduce background contrast.'
  const isReverse = false
  return (
    <>
      <div
        className={cx(
          'flex flex-col w-full py-16 sm:flex-row sm:items-center sm:justify-between',
          isReverse ? 'sm:flex-row-reverse' : ''
        )}
        style={{ maxWidth: '810px' }}
      >
        <div className="flex flex-col max-w-sm min-h-full">
          <div>
            <img src={orderIcon} alt="save-battery-icon" />
          </div>
          <div className="text-secondary-white leading-none text-2xl pt-6">{title}</div>
          <div
            className={cx(
              'text-secondary-white leading-normal text-base md:text-lg pt-6',
              css`
                @apply w-auto;
                @media (min-width: 768px) {
                  max-width: 360px;
                }
              `
            )}
          >
            {desc}
          </div>
        </div>
        <div className={cx('pt-8 sm:pt-0 sm:max-w-sm sm:min-h-full flex justify-center')}>
          <BatterySvg />
        </div>
      </div>

      <hr
        className={css`
          color: #414549;
        `}
      />
    </>
  )
}

export default SpecificFeatureSaveBattery
