import React from 'react'
import { cx, css } from 'linaria'
import _02 from './02.png'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const SpecificFeatureNightMode = () => {
  const orderIcon = _02
  const title = 'Working at night'
  const desc =
    'Reduce the brightness of macOS interface without sacrificing legibility or color accuracy of the front window.'
  const isReverse = true
  const data = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "working-at-night.png" }) {
        childImageSharp {
          fixed(width: 244, height: 244) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      desktopLogo: file(relativePath: { eq: "working-at-night.png" }) {
        childImageSharp {
          fixed(width: 263, height: 263) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [
    data?.mobileLogo?.childImageSharp.fixed,
    {
      ...data?.desktopLogo?.childImageSharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  return (
    <>
      <div
        className={cx(
          'flex flex-col w-full py-16 justify-center sm:flex-row sm:items-center sm:justify-between',
          isReverse ? 'sm:flex-row-reverse' : '',
          css`
            border-bottom: 1px #414549 solid;
          `
        )}
        style={{ maxWidth: '810px' }}
      >
        <div className="flex flex-col max-w-sm min-h-full">
          <div>
            <img src={orderIcon} alt="order-icon" />
          </div>
          <div className="text-secondary-white leading-none text-2xl pt-6">{title}</div>
          <div
            className={cx(
              'text-secondary-white leading-normal text-base md:text-lg pt-6',
              css`
                @apply w-auto;
                @media (min-width: 768px) {
                  max-width: 360px;
                }
              `
            )}
          >
            {desc}
          </div>
        </div>
        <div className={cx('pt-8 sm:pt-0 sm:max-w-sm sm:min-h-full flex  justify-center')}>
          <Img fixed={sources} alt="featur-night-mode" />
        </div>
      </div>

      <hr
        className={css`
          color: #414549;
        `}
      />
    </>
  )
}

export default SpecificFeatureNightMode
