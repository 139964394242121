import React from 'react'
import { cx, css } from 'linaria'
import _01 from './01.png'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const SpecificFeatureMultiDisplay = () => {
  const orderIcon = _01
  const title = 'Multiple displays support'
  const desc = 'Blurred app will let you know which screen is focused or highlight a window on each monitor.'
  const isReverse = false
  const data = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "window.png" }) {
        childImageSharp {
          fixed(width: 335, height: 236) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      desktopLogo: file(relativePath: { eq: "window.png" }) {
        childImageSharp {
          fixed(width: 400, height: 282) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [
    data?.mobileLogo?.childImageSharp.fixed,
    {
      ...data?.desktopLogo?.childImageSharp?.fixed,
      media: `(min-width: 1024px)`,
    },
  ]
  return (
    <>
      <div
        className={cx(
          'flex flex-col w-full justify-center py-16 sm:flex-row sm:items-center sm:justify-between',
          isReverse ? 'sm:flex-row-reverse' : '',
          css`
            border-bottom: 1px #414549 solid;
          `
        )}
        style={{ maxWidth: '810px' }}
      >
        <div className="flex flex-col max-w-sm min-h-full">
          <div>
            <img src={orderIcon} alt="order-icon" />
          </div>
          <div className="text-secondary-white leading-none text-2xl pt-6">{title}</div>
          <div
            className={cx(
              'text-secondary-white leading-normal text-base md:text-lg pt-6',
              css`
                @apply w-auto;
                @media (min-width: 768px) {
                  max-width: 354px;
                }
              `
            )}
          >
            {desc}
          </div>
        </div>
        <div
          className={cx(
            'pt-8 sm:pt-0 sm:max-w-sm sm:min-h-full flex justify-center',
            css`
              margin-left: -4px;
              @media (min-width: 768px) {
                margin-left: 0;
              }
            `
          )}
        >
          <Img fixed={sources} alt="window-picture" />
        </div>
      </div>

      <hr
        className={css`
          color: #414549;
        `}
      />
    </>
  )
}

export default SpecificFeatureMultiDisplay
