import React from 'react'

import SpecificFeatureMultiDisplay from './specific-feature-multi-display'
import SpecificFeatureNightMode from './specific-feature-night-mode'
import SpecificFeatureSaveBattery from './specific-feature-save-battery'

export const SpecificFeatures = () => {
  return (
    <div className="flex flex-col bg-lighterDark px-4 items-center">
      <SpecificFeatureMultiDisplay />
      <SpecificFeatureNightMode />
      <SpecificFeatureSaveBattery />
    </div>
  )
}
