import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'

import { BlurredApp as AppDetail } from '@components/contants'
import { Base } from '@ui/base'
import Seo from '@components/seo-detail-page'
import { Header } from '@components/ui/header'
import { CheckOn } from '@ui/check-on'
import { Jumbotron } from '@components/ui/jumbotron'
import { Intro } from '@components/blurred/intro'
import { SpecificFeatures } from '@components/blurred/specific-features'
import { DownloadNow } from '@ui/download-now'
import { Footer } from '@components/ui/footer'
import { CustomerReviews } from '@components/ui/customer-reviews'
import demoVideo from '@/assets/video/demo-blurred.mp4'
import bgWindows from '@/images/blurred/windows.png'
import boxPng from '@/images/blurred/box.png'

const BlurredDetailsPage: React.FC = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)
  return (
    <>
      <Seo {...AppDetail} video={demoVideo} images={images} />

      <Base>
        <Header bgColor="bg-grey top-0 z-50 sticky" />
        <Jumbotron
          {...AppDetail}
          bgClass={css`
            background: url(${bgWindows}) no-repeat center bottom #1e1e1e;
            background-size: 90% auto;
            @screen md {
              background-size: auto 60%;
            }
          `}
        />
        <Intro />
        <SpecificFeatures />
        <CheckOn {...AppDetail} />
        <CustomerReviews
          title="Customer Reviews"
          content="Based on 1 recent rating and reviews from"
          rating="5.0"
          seeAll={false}
          {...AppDetail}
        />
        <DownloadNow {...AppDetail} boxImage={boxPng} />
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/blurred/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

export default BlurredDetailsPage
